import "./hb-navbar.module.css";
import { html } from "../../global.ts";

const template = document.createElement("template");
template.innerHTML = html`
    <nav
        class="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
        <div
            class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <aside class="flex items-center space-x-3 rtl:space-x-reverse">
                <button
                    data-drawer-target="drawer-navigation"
                    data-drawer-show="drawer-navigation"
                    type="button"
                    class="inline-flex items-center p-2 w-10 h-8 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                    aria-controls="navbar-sticky"
                    aria-expanded="false">
                    <span class="sr-only">Open main menu</span>
                    <svg
                        class="w-5 h-5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 17 14">
                        <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M1 1h15M1 7h15M1 13h15" />
                    </svg>
                </button>
                <span
                    class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"
                    >Treasure Map</span
                >
            </aside>
            <div
                class="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                <button
                    data-drawer-target="drawer-top-example"
                    data-drawer-show="drawer-top-example"
                    data-drawer-placement="top"
                    aria-controls="drawer-top-example"
                    type="button"
                    class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                    Get started
                </button>
            </div>
        </div>
    </nav>
`;

export default template;
