import template from "./hb-theme.mjs";

export class HbTheme extends HTMLElement {
    private lightInput: HTMLInputElement;
    private darkInput: HTMLInputElement;
    private lightLabel: HTMLLabelElement;
    private darkLabel: HTMLLabelElement;

    constructor() {
        super();
        this.appendChild(template.content.cloneNode(true));

        this.lightInput = this.querySelector("#light")!;
        this.darkInput = this.querySelector("#dark")!;
        this.lightLabel = this.lightInput.parentElement as HTMLLabelElement;
        this.darkLabel = this.darkInput.parentElement as HTMLLabelElement;
    }

    connectedCallback() {
        this.render();

        window
            .matchMedia("(prefers-color-scheme: dark)")
            .addEventListener("change", (event: MediaQueryListEvent) => {
                this.render();
            });

        this.lightInput.addEventListener("change", (event: Event) => {
            localStorage.colorScheme = this.lightInput.checked
                ? "dark"
                : "system";
            this.render();
        });

        this.darkInput.addEventListener("change", (event: Event) => {
            localStorage.colorScheme = this.darkInput.checked
                ? "light"
                : "system";
            this.render();
        });
    }

    private render() {
        if (localStorage.colorScheme === "dark") {
            this.lightLabel.style.display = "block";
            this.darkLabel.style.display = "none";
            this.lightInput.checked = true;
            this.darkInput.checked = false;
            document.documentElement.classList.add("dark");
        } else if (localStorage.colorScheme === "light") {
            this.lightLabel.style.display = "none";
            this.darkLabel.style.display = "block";
            this.lightInput.checked = false;
            this.darkInput.checked = true;
            document.documentElement.classList.remove("dark");
        } else {
            if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
                this.lightLabel.style.display = "none";
                this.darkLabel.style.display = "block";
                this.lightInput.checked = false;
                this.darkInput.checked = false;
                document.documentElement.classList.add("dark");
            } else {
                this.lightLabel.style.display = "block";
                this.darkLabel.style.display = "none";
                this.lightInput.checked = false;
                this.darkInput.checked = false;
                document.documentElement.classList.remove("dark");
            }
        }
    }
}
