import "./hb-main.module.css";
import { html } from "../global.ts";
import { MAPKIT_TOKEN } from "../global.ts";
import treasureMap from "./hb-earth/index.html";

const template = document.createElement("template");
template.innerHTML = html`
    <iframe src="${treasureMap}&token=${MAPKIT_TOKEN}"></iframe>
`;

export default template;
