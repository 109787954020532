import template from "./hb-main.mjs";
import { HeartBank } from "../../managers/HeartBank.ts";

import { HbSettings } from "./hb-settings/hb-settings.ts";
import { HbChest } from "./hb-chest/hb-chest.ts";

customElements.define("hb-admin", HbSettings);
customElements.define("hb-chest", HbChest);

export class HbMain extends HTMLElement {
    private heartbank: HeartBank = new HeartBank();
    private iframe: HTMLIFrameElement;

    constructor() {
        super();
        this.appendChild(template.content.cloneNode(true));
        this.iframe = this.querySelector("iframe")!;
    }

    connectedCallback() {
        window.addEventListener(
            "message",
            (event: MessageEvent<{ status: string }>) => {
                if (event.origin !== window.location.origin) return;
                console.log(event.data);
            }
        );

        this.heartbank.addAuthStateListener((user) => {
            if (user) {
                //this.main.textContent = `Welcome, ${user.email}`;
            } else {
                //this.main.textContent = `download app, `;
            }
        });

        setTimeout(() => {
            this.sendMessage();
        }, 3000);
    }

    sendMessage() {
        this.iframe.contentWindow?.postMessage(
            {
                action: "updateColorScheme",
                data: {
                    latitude: 40.7128, // New York City
                    longitude: -74.006
                }
            },
            window.location.origin
        );
    }
}
