import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    Auth,
    User,
} from "firebase/auth";
import { auth } from "../firebase.ts";

export class HeartBank {
    private auth: Auth = auth;
    user: User | null = null;

    constructor() {
        onAuthStateChanged(this.auth, (user) => (this.user = user));
    }

    async signUp(email: string, password: string): Promise<User> {
        try {
            const credential = await createUserWithEmailAndPassword(
                this.auth,
                email,
                password
            );
            //console.log("User signed up successfully:", credential.user);
            return credential.user;
        } catch (error) {
            //console.error("Error during sign-up:", error);
            throw error;
        }
    }

    async signIn(email: string, password: string): Promise<User> {
        try {
            const credential = await signInWithEmailAndPassword(
                this.auth,
                email,
                password
            );
            //console.log("User logged in successfully:", credential.user);
            return credential.user;
        } catch (error) {
            //console.error("Error during login:", error);
            throw error;
        }
    }

    async SignOut() {
        try {
            await signOut(this.auth);
            //console.log("User logged out successfully");
        } catch (error) {
            //console.error("Error during logout:", error);
            throw error;
        }
    }

    addAuthStateListener(callback: (user: User | null) => void) {
        onAuthStateChanged(this.auth, callback);
    }
}
