import template from "./hb-tabbar.mjs";
import { Drawer } from "flowbite";
import type { DrawerOptions, DrawerInterface } from "flowbite";
import type { InstanceOptions } from "flowbite";

export class HbTabBar extends HTMLElement {
    private drawer: DrawerInterface;
    private tabBar: HTMLElement;

    private light: HTMLElement;
    private dark: HTMLElement;
    private auto: HTMLElement;

    constructor() {
        super();
        this.appendChild(template.content.cloneNode(true));

        this.tabBar = this.querySelector("#drawer-swipe")!;
        this.light = this.querySelector("#hb-light-mode")!;
        this.dark = this.querySelector("#hb-dark-mode")!;
        this.auto = this.querySelector("#hb-auto-mode")!;

        const options: DrawerOptions = {
            placement: "bottom",
            backdrop: true,
            bodyScrolling: false,
            edge: false,
            edgeOffset: "bottom-[70px]", // doesn't work
            backdropClasses:
                "bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30",
            onHide: () => {
                this.tabBar.classList.replace("bottom-0", "bottom-[70px]");
            },
            onShow: () => {
                this.tabBar.classList.replace("bottom-[70px]", "bottom-0");
            },
            onToggle: () => {}
        };

        // instance options object
        const instanceOptions: InstanceOptions = {
            id: "drawer-swipe",
            override: true
        };

        this.drawer = new Drawer(this.tabBar, options, instanceOptions);
    }

    connectedCallback() {
        this.render();
        this.addColorSchemeListeners();
    }

    private render() {
        switch (localStorage.colorScheme) {
            case "dark":
                document.documentElement.classList.add("dark");
                break;
            case "light":
                document.documentElement.classList.remove("dark");
                break;
            default:
                if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
                    document.documentElement.classList.add("dark");
                } else {
                    document.documentElement.classList.remove("dark");
                }
        }
    }

    private addColorSchemeListeners() {
        window
            .matchMedia("(prefers-color-scheme: dark)")
            .addEventListener("change", (event: MediaQueryListEvent) => {
                this.render();
                //logEvent(analytics, "color", { scheme: "dark" });
            });

        this.light.addEventListener("click", (event: Event) => {
            localStorage.setItem("colorScheme", "light");
            this.render();
            this.drawer.hide();
            this.dispatchColorSchemeEvent("light");
        });

        this.dark.addEventListener("click", (event: Event) => {
            localStorage.setItem("colorScheme", "dark");
            this.render();
            this.drawer.hide();
            this.dispatchColorSchemeEvent("dark");
        });

        this.auto.addEventListener("click", (event: Event) => {
            localStorage.setItem("colorScheme", "system");
            this.render();
            this.drawer.hide();
            this.dispatchColorSchemeEvent("system");
        });
    }

    private dispatchColorSchemeEvent(colorScheme: string) {
        this.dispatchEvent(
            new CustomEvent<{ colorScheme: string }>("hb-color-scheme", {
                bubbles: true,
                detail: {
                    colorScheme
                }
            })
        );

        //logEvent(analytics, "color", { scheme: colorScheme });
    }
}
