import "./hb-settings.module.css";
import { html } from "../../global.ts";

const template = document.createElement("template");
template.innerHTML = html`
    <form>
        <input type="text" name="todo" placeholder="Add a todo" required />
        <button type="submit">Add</button>
        <ul></ul>
    </form>
`;

export default template;
