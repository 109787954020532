import { HbHeader } from "./hb-header/hb-header.ts";
import { HbMain } from "./hb-main/hb-main.ts";
import { HbFooter } from "./hb-footer/hb-footer.ts";

customElements.define("hb-header", HbHeader);
customElements.define("hb-main", HbMain);
customElements.define("hb-footer", HbFooter);

export class HbTreasury extends HTMLBodyElement {
    constructor() {
        super();
    }

    async connectedCallback() {
        window.addEventListener("popstate", this.updateView.bind(this));
    }

    private async updateView() {
        const paths = window.location.hash.split("/");

        switch (paths[2]) {
            case "treasury":
                //this.article.textContent = `purchase storage plan at hb.com`;
                break;
            case "trove": // profile
                //this.article.textContent = `purchase storage plan at hb.com`;
                break;
            case "chest": // tag = memories
                //this.article.textContent = `purchase storage plan at hb.com`;
                break;
            case "treasure": // memory
                //this.article.textContent = `purchase storage plan at hb.com`;
                break;
            case "login":
                //this.article.textContent = `become member for free shipping at hb.org`;
                break;
            case "settings":
                //this.article.textContent = `become member for free shipping at hb.org`;
                break;
            default: // home = map
            //this.article.textContent = `download app, buttons to buy membership and storage plans`;
        }
    }
}
