import template from "./hb-footer.mjs";
import { HeartBank } from "../../managers/HeartBank.ts";

import { HbTabBar } from "./hb-tabbar/hb-tabbar.ts";
customElements.define("hb-tabbar", HbTabBar);

export class HbFooter extends HTMLElement {
    private heartbank: HeartBank = new HeartBank();

    constructor() {
        super();
        this.appendChild(template.content.cloneNode(true));
    }

    async connectedCallback() {
        this.heartbank.addAuthStateListener((user) => {
            if (user) {
                //this.main.textContent = `Welcome, ${user.email}`;
            } else {
                //this.main.textContent = `download app, `;
            }
        });
    }
}
