import template from "./hb-settings.mjs";
import { HeartBank } from "../../../managers/HeartBank.ts";

export class HbSettings extends HTMLElement {
    private heartbank: HeartBank = new HeartBank();

    private form: HTMLFormElement;
    private ul: HTMLUListElement;

    constructor() {
        super();
        this.appendChild(template.content.cloneNode(true));

        this.form = this.querySelector("form")!;
        this.ul = this.querySelector("ul")!;
    }

    async connectedCallback() {
        this.form.addEventListener("submit", (e: Event) =>
            this.handleAddTodo(e)
        );
        this.render();
    }

    render() {
        this.ul.innerHTML = "";

        // this.data.dexieDb.todos.each((todo) => {
        //     const li = document.createElement("li");

        //     const checkbox = document.createElement("input");
        //     checkbox.type = "checkbox";
        //     checkbox.checked = todo.completed;
        //     checkbox.addEventListener("change", async () => {
        //         await this.data.toggleTodo(todo.id!);
        //         this.render();
        //     });
        //     li.appendChild(checkbox);

        //     li.appendChild(document.createTextNode(todo.text));

        //     const button = document.createElement("button");
        //     button.textContent = "Delete";
        //     button.addEventListener("click", async () => {
        //         await this.data.deleteTodo(todo.id!);
        //         this.render();
        //     });
        //     li.appendChild(button);

        //     this.ul.appendChild(li);
        // });
    }

    async handleAddTodo(e: Event) {
        // e.preventDefault();
        // const input = (e.target as HTMLFormElement).elements.namedItem(
        //     "todo"
        // ) as HTMLInputElement;
        // await this.data.addTodo(input.value);
        // input.value = "";
        // this.render();
    }
}
