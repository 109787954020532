import template from "./hb-chest.mjs";
import { HeartBank } from "../../../managers/HeartBank.ts";

export class HbChest extends HTMLElement {
    private heartbank: HeartBank = new HeartBank();

    constructor() {
        super();
        this.appendChild(template.content.cloneNode(true));
    }

    async connectedCallback() {
        this.heartbank.addAuthStateListener((user) => {
            if (user) {
                //this.main.textContent = `Welcome, ${user.email}`;
            } else {
                //this.main.textContent = `Hello World!`;
            }
        });
    }
}
