import template from "./hb-header.mjs";
import { HeartBank } from "../../managers/HeartBank.ts";

import { HbNavBar } from "./hb-navbar/hb-navbar.ts";
import { HbMenu } from "./hb-menu/hb-menu.ts";
import { HbOnboard } from "./hb-onboard/hb-onboard.ts";
import { HbLogin } from "./hb-login/hb-login.ts";

customElements.define("hb-navbar", HbNavBar);
customElements.define("hb-menu", HbMenu);
customElements.define("hb-onboard", HbOnboard);
customElements.define("hb-login", HbLogin);

export class HbHeader extends HTMLElement {
    private heartbank: HeartBank = new HeartBank();

    constructor() {
        super();
        this.appendChild(template.content.cloneNode(true));
    }

    async connectedCallback() {
        this.heartbank.addAuthStateListener((user) => {
            if (user) {
                //this.main.textContent = `Welcome, ${user.email}`;
            } else {
                //this.main.textContent = `download app, `;
            }
        });
    }
}
