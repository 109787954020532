import template from "./hb-onbard.mjs";
import { HeartBank } from "../../../managers/HeartBank.ts";

export class HbOnboard extends HTMLElement {
    private heartbank: HeartBank = new HeartBank();

    // private download: HTMLElement;
    // private membership: HTMLElement;
    // private storage: HTMLElement;
    // private login: HTMLElement;
    // private logout: HTMLElement;

    constructor() {
        super();
        this.appendChild(template.content.cloneNode(true));
        // this.download = this.querySelector("#download")!;
        // this.membership = this.querySelector("#membership")!;
        // this.storage = this.querySelector("#storage")!;
        // this.login = this.querySelector("#login")!;
        // this.logout = this.querySelector("#logout")!;
    }

    connectedCallback() {
        // this.menuNavigation();
        // this.menuHighlight();
        // this.heartbank.addAuthStateListener((user) => {
        //     this.logout.style.display = user ? "block" : "none";
        //     this.login.style.display = user ? "none" : "block";
        // });
    }

    private menuNavigation() {
        // this.download.addEventListener("click", () => router.navigateTo("/"));
        // this.membership.addEventListener("click", () =>
        //     router.navigateTo("/#membership")
        // );
        // this.storage.addEventListener("click", () =>
        //     router.navigateTo("/#storage")
        // );
        // this.login.addEventListener("click", () => router.navigateTo("/login"));
        // this.logout.addEventListener("click", () => {
        //     this.heartbank.SignOut();
        //     router.navigateTo("/");
        // });
    }

    private menuHighlight() {
        // const path = window.location.pathname;
        // const hash = window.location.hash;
        // switch (path + hash) {
        //     case "/":
        //         this.download.style.textDecorationLine = "underline";
        //         break;
        //     case "/#membership":
        //         this.membership.style.textDecorationLine = "underline";
        //         break;
        //     case "/#storage":
        //         this.storage.style.textDecorationLine = "underline";
        //         break;
        //     case "/login":
        //         this.login.style.textDecorationLine = "underline";
        //         break;
        // }
    }
}
