import template from "./hb-login.mjs";
import { HeartBank } from "../../../managers/HeartBank.ts";

export class HbLogin extends HTMLElement {
    private heartbank: HeartBank = new HeartBank();

    private form: HTMLFormElement;
    private h2: HTMLHeadingElement;
    private button: HTMLButtonElement;
    private email: HTMLInputElement;
    private password: HTMLInputElement;

    constructor() {
        super();
        this.appendChild(template.content.cloneNode(true));

        this.form = this.querySelector("form")!;
        this.h2 = this.querySelector("h2")!;
        this.button = this.querySelector("button")!;
        this.email = this.querySelector("#email")!;
        this.password = this.querySelector("#password")!;
    }

    connectedCallback() {
        const hash = window.location.hash.substring(1);

        if (hash === "signup") {
            this.h2.innerText = "Sign Up";
            this.button.innerText = "Sign Up";
            this.form.addEventListener("submit", this.handleSignup.bind(this));
        } else {
            this.h2.innerText = "Log In";
            this.button.innerText = "Log In";
            this.form.addEventListener("submit", this.handleLogin.bind(this));
        }

        this.heartbank.addAuthStateListener((user) => {
            if (user) {
                //router.navigateTo("/");
            }
        });
    }

    private async handleSignup(event: Event) {
        event.preventDefault();

        try {
            const user = await this.heartbank.signUp(
                this.email.value,
                this.password.value
            );
            //router.navigateTo("/chronicle"); //TODO: redirect to last chronicle or tag
        } catch (error: any) {
            alert(`Error during signup: ${error.message}`);
        }
    }

    private async handleLogin(event: Event) {
        event.preventDefault();

        try {
            const user = await this.heartbank.signIn(
                this.email.value,
                this.password.value
            );
            //router.navigateTo("/tag"); //TODO: redirect to last chronicle or tag
        } catch (error: any) {
            alert("Error during login: " + error.message);
        }
    }
}
