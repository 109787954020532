import "./hb-login.module.css";
import { html } from "../../global.ts";

const template = document.createElement("template");
template.innerHTML = html`
    <h2></h2>
    <form>
        <div>
            <label for="email">Email</label>
            <input
                type="email"
                id="email"
                name="email"
                placeholder="you@example.com"
                required />
        </div>
        <div>
            <label for="password">Password</label>
            <input
                type="password"
                id="password"
                name="password"
                placeholder="Enter your password"
                required />
        </div>
        <aside>
            <input id="remember-me" type="checkbox" />
            <label for="remember-me">Remember me</label>
        </aside>
        <button type="submit"></button>
    </form>
`;

export default template;
