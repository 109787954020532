export function html(strings: TemplateStringsArray, ...values: any[]): string {
    // Combine the strings and interpolated values into a single string
    return strings.reduce(
        (result, str, i) => result + str + (values[i] || ""),
        ""
    );
}

export const MAPKIT_TOKEN =
    process.env.NODE_ENV === "production"
        ? "eyJraWQiOiJUTkpGUFU2TjVRIiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiI5NDM5U1hEOTk3IiwiaWF0IjoxNzM3NTY3OTA0LCJvcmlnaW4iOiIqLmhlYXJ0YmFuay51cyJ9.8ORHAZjkup1r-7jPFTfJ02ZeElmRRGcjcyO7xfwnChvm1P44gFj6OB46xX2EBbujI_aCUyo5llTNPvqf9P6mdQ"
        : "eyJraWQiOiJWTlEyV0c5V1pWIiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiI5NDM5U1hEOTk3IiwiaWF0IjoxNzM3NDc4MzM2LCJleHAiOjE3MzgxMzc1OTl9.QEbTg_z_GRCW1OALsGWoHBxFi3Lsrc0I4YelEOBFAL5GmRsby3R66-GlG6vjlfG926qA-6qQD9f8LVmJ03wfkA";
