import "./hb-theme.module.css";
import { html } from "../../global.ts";

const template = document.createElement("template");
template.innerHTML = html`
    <aside class="absolute bottom-20 right-10 z-10">
        <label class="inline-flex items-center cursor-pointer">
            <input id="light" type="checkbox" value="" class="sr-only peer" />
            <div
                class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <!--<span class="ms-3 text-xs font-medium text-gray-900 dark:text-gray-300">Dark Mode</span>-->
        </label>

        <label class="inline-flex items-center cursor-pointer">
            <input id="dark" type="checkbox" value="" class="sr-only peer" />
            <div
                class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <!--<span class="ms-3 text-xs font-medium text-gray-900 dark:text-gray-300">Light Mode</span>-->
        </label>
    </aside>
`;

export default template;
