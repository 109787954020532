export function html(strings: TemplateStringsArray, ...values: any[]): string {
    // Combine the strings and interpolated values into a single string
    return strings.reduce(
        (result, str, i) => result + str + (values[i] || ""),
        ""
    );
}

export const MAPKIT_TOKEN =
    process.env.NODE_ENV === "production"
        ? "eyJraWQiOiJDTTI3TkFGNkZHIiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiI5NDM5U1hEOTk3IiwiaWF0IjoxNzM2MjU2MzQ4LCJvcmlnaW4iOiIqLmhlYXJ0YmFuay5jb20ifQ.rslhPsCFfVqkaLqXa-8NBsDjZpjUtKdMk2P9uTQuuQSzXoM7_QmJG9Vwvzuonu5CYcr_dd1cN9tXqI5ll9VkOQ"
        : "eyJraWQiOiJWTlEyV0c5V1pWIiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiI5NDM5U1hEOTk3IiwiaWF0IjoxNzM3NDc4MzM2LCJleHAiOjE3MzgxMzc1OTl9.QEbTg_z_GRCW1OALsGWoHBxFi3Lsrc0I4YelEOBFAL5GmRsby3R66-GlG6vjlfG926qA-6qQD9f8LVmJ03wfkA";
