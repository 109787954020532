import template from "./hb-main.mjs";
import { HeartBank } from "../../managers/HeartBank.ts";

import { HbSettings } from "./hb-settings/hb-settings.ts";
import { HbChest } from "./hb-chest/hb-chest.ts";

customElements.define("hb-admin", HbSettings);
customElements.define("hb-chest", HbChest);

export class HbMain extends HTMLElement {
    private heartbank: HeartBank = new HeartBank();
    private iframe: HTMLIFrameElement;

    constructor() {
        super();
        this.appendChild(template.content.cloneNode(true));
        this.iframe = this.querySelector("iframe")!;
    }

    connectedCallback() {
        this.addColorSchemeListeners();
        this.addIframeListeners();

        this.heartbank.addAuthStateListener((user) => {
            if (user) {
                //this.main.textContent = `Welcome, ${user.email}`;
            } else {
                //this.main.textContent = `download app, `;
            }
        });
    }

    private addIframeListeners() {
        window.addEventListener(
            "message",
            (event: MessageEvent<{ action: string; data?: any }>) => {
                if (event.origin !== window.location.origin) return;
                switch (event.data.action) {
                    case "colorScheme":
                        console.log(event.data);
                        break;
                    case "chestSelected":
                        console.log(event.data);
                        break;
                    case "status":
                        console.log(event.data);
                        break;
                    default:
                    //console.log("unknown action", event.data);
                }
            }
        );
    }

    private addColorSchemeListeners() {
        document.body.addEventListener("hb-color-scheme", (event: Event) => {
            const customEvent = event as CustomEvent<{
                colorScheme: string;
            }>;

            //localStorage.setItem("colorScheme", customEvent.detail.colorScheme);

            this.iframe.contentWindow?.postMessage(
                {
                    action: "updateColorScheme",
                    data: {
                        colorScheme: customEvent.detail.colorScheme
                    }
                },
                window.location.origin
            );
        });
    }
}
