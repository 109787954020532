import "./hb-header.module.css";
import { html } from "../global.ts";

const template = document.createElement("template");
template.innerHTML = html`
    <hb-navbar></hb-navbar>
    <hb-menu></hb-menu>
    <hb-onboard></hb-onboard>
`;

export default template;
