import template from "./hb-footer.mjs";
import { HeartBank } from "../../managers/HeartBank.ts";

import { Drawer } from "flowbite";
import type { DrawerOptions, DrawerInterface } from "flowbite";
import type { InstanceOptions } from "flowbite";

import { HbOnboard } from "./hb-onboard/hb-onboard.ts";
import { HbTheme } from "./hb-theme/hb-theme.ts";

customElements.define("hb-onboard", HbOnboard);
customElements.define("hb-theme", HbTheme);

export class HbFooter extends HTMLElement {
    private heartbank: HeartBank = new HeartBank();
    private drawer: DrawerInterface;

    private tabBar: HTMLElement;
    private tabView: HTMLElement;

    constructor() {
        super();
        this.appendChild(template.content.cloneNode(true));

        this.tabView = this.querySelector("#drawer-swipe")!;
        this.tabBar = this.querySelector("div[data-drawer-toggle]")!;

        const options: DrawerOptions = {
            placement: "bottom",
            backdrop: true,
            bodyScrolling: false,
            edge: false,
            edgeOffset: "bottom-[70px]", // doesn't work
            backdropClasses:
                "bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30",
            onHide: () => {
                this.tabView.classList.replace("bottom-0", "bottom-[70px]");
            },
            onShow: () => {
                this.tabView.classList.replace("bottom-[70px]", "bottom-0");
            },
            onToggle: () => {}
        };

        // instance options object
        const instanceOptions: InstanceOptions = {
            id: "drawer-swipe",
            override: true
        };

        this.drawer = new Drawer(this.tabView, options, instanceOptions);
    }

    async connectedCallback() {
        this.heartbank.addAuthStateListener((user) => {
            if (user) {
                //this.main.textContent = `Welcome, ${user.email}`;
            } else {
                //this.main.textContent = `download app, `;
            }
        });
    }
}
