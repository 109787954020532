import template from "./hb-menu.mjs";

export class HbMenu extends HTMLElement {
    constructor() {
        super();
        this.appendChild(template.content.cloneNode(true));
    }

    connectedCallback() {}
}
